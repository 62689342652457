import { NgModule } from "@angular/core";
import { DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AgmCoreModule } from "@agm/core";
import { HttpClientModule, HttpClient, HttpHeaders } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxSpinnerModule } from 'ngx-spinner';
import { DragulaService } from "ng2-dragula";
import { AgGridModule } from 'ag-grid-angular';
import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider } from 'angularx-social-login';
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import { AppComponent } from "./app.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { AuthService } from "./shared/auth/auth.service";
import { AuthGuard } from "./shared/auth/auth-guard.service";
import { WINDOW_PROVIDERS } from './shared/services/window.service';

// DIRECTIVES
//import { LowercaseInputDirective } from "./shared/directives/lowercase-input.directive";

var firebaseConfig = {
  apiKey: "YOUR_API_KEY", //YOUR_API_KEY
  authDomain: "YOUR_AUTH_DOMAIN", //YOUR_AUTH_DOMAIN
  databaseURL: "YOUR_DATABASE_URL", //YOUR_DATABASE_URL
  projectId: "YOUR_PROJECT_ID", //YOUR_PROJECT_ID
  storageBucket: "YOUR_STORAGE_BUCKET", //YOUR_STORAGE_BUCKET
  messagingSenderId: "YOUR_MESSAGING_SENDER_ID", //YOUR_MESSAGING_SENDER_ID
  appId: "YOUR_APP_ID", //YOUR_APP_ID
  measurementId: "YOUR_MEASUREMENT_ID" //YOUR_MEASUREMENT_ID
};

var httpHeader = {
  headers: new HttpHeaders({
    'content-type': 'application/json'
  })
}

var listCompanyCategory: any[];
var listCompanyProfile: any[];
var listMachineCategory: any[];
var listMachineType: any[];
var listCompanyBrand: any[];
var listMasterBrandName: any[];

//var apiUrl: string = "http://localhost:38501";
var apiUrl: string = "https://api.zatpatmachines.com";

//var apiUrl: string = "http://zapi.visioninformatics.in";
//var apiUrl: string = "http://api.zatpatcrm.com";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent, FullLayoutComponent, ContentLayoutComponent],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    SocialLoginModule,
    NgbModule,
    AgGridModule,
    NgxSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: "YOUR_GOOGLE_MAP_API_KEY"
    }),
    PerfectScrollbarModule
  ],
  providers: [
    DatePipe,
    AuthService,
    AuthGuard,
    DragulaService,
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: 'httpHeader', useValue: httpHeader},
    { provide: 'APIURL', useValue: apiUrl},
    { provide: 'listCompanyCategory', useValue: listCompanyCategory},
    { provide: 'listCompanyProfile', useValue: listCompanyProfile},
    { provide: 'listMachineCategory', useValue: listMachineCategory},
    { provide: 'listMachineType', useValue: listMachineType},
    { provide: 'listCompanyBrand', useValue: listCompanyBrand},
    { provide: 'listMasterBrandName', useValue: listMasterBrandName},
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '359252288013-vlgag6j01122m7v42qs3jj6c57s3f9eg.apps.googleusercontent.com'
            )
          },
        ]
      } as SocialAuthServiceConfig,
    },
    WINDOW_PROVIDERS
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
